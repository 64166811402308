<template>
  <!-- 入库详情 -->
  <div class="stockDetails page-info-content">
    <DetailsPage :detail-obj="inventoryDetailObj" :details-item-arr="detailsItemArr" table-title="货位明细" :item-data="itemData" :list-data="inventoryDetailObj.wmsInventoryIndetailRespList" />
    <ProductNamePopUpLayer :id="pnid" ref="ProductNamePopUpLayer" />
  </div>
</template>

<script>
import ProductNamePopUpLayer from '../components/ProductNamePopUpLayer'
import DetailsPage from '@/components/DetailsPage'
import { inventoryInDetail } from '../../../api/warehousingSystem'
export default {
  components: { ProductNamePopUpLayer, DetailsPage },
  data() {
    return {
      pnid: '',
      inventoryDetailObj: {},
      detailsItemArr: [
        { label: '入库单号', value: 'inNo' },
        { label: '入库类型', value: 'type', child: [{ dictId: '01', dictName: '普通入库' }, { dictId: '02', dictName: '代采入库' }, { dictId: '03', dictName: '集采入库' }] },
        { label: '合同编号', value: 'contractNo' },
        { label: '仓库名称', value: 'warehouseName' },
        { label: '货主名称', value: 'custName' },
        { label: '入库经办人', value: 'inReceiver' },
        { label: '入库日期', value: 'inDate' },
        { label: '送货人', value: 'driver' },
        { label: '车牌号', value: 'carNo' },
        { label: '运单号', value: 'waybillNo' },
        { label: '备注', value: 'remark' }
      ],
      itemData: [
        { label: '品名', prop: 'detailGoodsName' },
        { label: '材质', prop: 'detailMaterials' },
        { label: '规格', prop: 'detailSpecs' },
        { label: '产地', prop: 'detailProductions' },
        { label: '件重(吨)', prop: 'detailWeights' },
        { label: '入库数量', prop: 'detailInWeight' },
        { label: '计量单位', prop: 'unit' },
        { label: '库区编号', prop: 'goodsYardNo' },
        { label: '货位号', prop: 'detailLocationNo' },
        { label: '货位描述', prop: 'detailLocationDesc' },
        { label: '下游客户名称', prop: 'detailDownCustName' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/warehousingSystem/stockManagement', name: '入库管理' }, { path: '', name: '详情' }]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      inventoryInDetail({ id: this.$route.query.id }, res => {
        // res.data.wmsInventoryIndetailRespList.forEach(item => {
        //   if (item.detailProductions === '-1')item.detailProductions = '不限'
        //   if (item.detailWeights === '-1')item.detailWeights = '不限'
        // })
        this.inventoryDetailObj = { ...res.data }
        this.pnid = this.inventoryDetailObj.goodsWeightsId
        this.inventoryDetailObj.driver = (res.data.driverName || '') + (res.data.driverPhone || '')
      })
    }
  },
  methods: {
    open() {
      this.$refs.ProductNamePopUpLayer.dialogTableVisible = true
    }
  }
}
</script>

<style>
.stockDetails .el-descriptions {
  padding: 40px 32px 0;
}
.cargoDetail {
  padding: 10px 32px!important;
}
.stockDetails .el-descriptions-row td {
  padding-bottom: 28px !important;
}
.stockDetails .tableBox {
  margin: auto;
  text-align: center;
  width: calc(100% - 128px);
  border-collapse: collapse;
}
.stockDetails .tableBox td {
  height: 40px;
  border: 1px solid #ededed;
}
.stockDetails .el-descriptions .el-descriptions__body .link {
  color: rgb(64, 158, 255);
  text-decoration: underline;
  cursor: pointer;
}
.stockDetails .el-descriptions .el-descriptions__body {
  padding: 0 32px;
}
</style>
